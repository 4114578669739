import React, { useState, useEffect } from "react";
import { View, Text, TouchableWithoutFeedback, StyleSheet } from "react-native";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

export function InputCountItem(props) {
  const [count, setCount] = useState(1);
  const { changeValue, initValue } = props;

  useEffect(() => {
    setCount(initValue > 1 ? initValue : 1 )
  },[]);
  useEffect(() => {
    changeValue(count);
  });
  return (
    <View style={styles.container}>
      <TouchableWithoutFeedback
        style={styles.contentButton}
        onPress={() => {
          if (count > 1) setCount(count - 1);
        }}
      >
        <Text>
          <IoIosRemove style={{ padding: 10, fontSize: 30 }} />
        </Text>
      </TouchableWithoutFeedback>
      <Text style={styles.contentView}>{count}</Text>
      <TouchableWithoutFeedback
        style={styles.contentButton}
        onPress={() => {
          setCount(count + 1);
        }}
      >
        <Text>
          <IoIosAdd style={{ padding: 10, fontSize: 30 }} />
        </Text>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
  },
  contentButton: {
    padding: 5,
    paddingHorizontal: 10,
  },
  contentView: {
    width: "25%",
    textAlign: "center",
    fontSize: 20,
  },
});
