import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  KeyboardAvoidingView,
  Alert,
  BackHandler,
} from "react-native";
import { useBackHandler } from "@react-native-community/hooks";
import { FaEllipsisV } from "react-icons/fa";
import Colors from "../../components/Colors";
import OptionItemModal from "../../components/OptionItemModal";
import Helper from "../../utils/Helper";
import { useOrderService } from "../../contexts/orderService";
import FormSign from "../../components/FormSign/FormSign";
import OptionsPayment from "../../components/OptionsPayment";
import msgWhats from "../../components/MsgWhats/MsgWhats";
import { useCookies } from "react-cookie";
import NavBar from "../../components/NavBar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import apiDelivery from "../../Api/apiDelivery";
import { useHistory } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import SelectDeliveryMethod from "../../components/SelectDeliveryMethod/SelectDeliveryMethod";
import Change from "../../components/Change/Change";
import ModalChange from "../../components/Change/ModalChange";
import ModalConfirmationOrder from "../../components/ModalConfirmationOrder";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { ActivityIndicator } from "react-native";
import "./styles.css";
import Voucher from "../../components/Voucher";
import sendNotification from "../../utils/sendNotification";
import { useColor } from "../../contexts/colors";
import NewCard from "../../components/NewCard";
import CardPayment from "../../components/CardsPayment";
import Swal from "sweetalert2";
import copy from "copy-to-clipboard";
import Preloader from "../../components/Preloader";
import styles from "./BagDetails.styles";
import { validarCPF } from "../../utils/validarCPF";
import { SafeAreaView, TextInput } from "react-native";
import { Button } from "react-native-web";
import { formatAddress } from "../../utils/formatAddress";

const windowHeight = Dimensions.get("window").height;

export default function BagDetail({ route, navigation }) {
  const {
    bag,
    neighborhood,
    removeItem,
    paymentForm,
    changeMoney,
    latitude,
    longitude,
    accuracy,
    orderMode,
    changeOrderMode,
    company: companyData,
    isFreeShipping,
    setIsFreeShipping,
    clearBag,
    setTotalVoucher,
    voucherValue,
    setVoucherValue,
    setShowBeforeUnload,
    voucherSelected,
    setEstablishment,
    isHigherValue,
    clearVoucher,
    totalVoucher,
    getOrderCode,
    honestMarket,
    SelectedChangeMoney,
  } = useOrderService();

  const { buttonColor, buttonTextColor, backgroundColor, textColor } =
    useColor();
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [usePix, setUsePix] = useState(false);
  const valueCpfRef = useRef();
  const [valueCpf, setValueCpf] = useState(localStorage.getItem("cpf") || "");
  const [requestMade, setRequestMade] = useState(false);
  const newCodeMarketSelf = useRef("");
  const [visibleModalPayment, setVisibleModalPayment] = useState(false);
  const [visibleModalVoucher, setVisibleModalVoucher] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [transactionIdPix, setTransactionIdPix] = useState([]);
  const [visibleFormSign, setVisibleFormSign] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [isVisibleNewCard, setIsVisibleNewCards] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pickupOnEstablishment, setPickupOnEstablishment] = useState(false);
  const [phone, setPhone] = useState("");
  const [cookies, setCookies] = useCookies(["user"]);
  const [creditCard, setCreditCard] = useState([]);
  const [useCard, setUseCard] = useState(false);
  const [user, setUser] = useState(null);
  const [searchCard, setSearchCard] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  let company = getSubDomain();
  let cards = JSON.parse(localStorage.getItem("history_card"));
  const isNotExternal = bag.find((value) => value.item.external === false);
  const isExternal = bag.find((value) => value.item.external === true);

  const clearCpf = () => {
    localStorage.removeItem("cpf");
    setValueCpf("");
  };

  const handleBackPress = () => {
    Alert.alert(
      "Tem certeza que deseja sair?",
      "Ao sair você irá cancelar seu pedido.",
      [
        {
          text: "Não",
          onPress: () => null,
          style: "cancel",
        },
        {
          text: "Sim",
          onPress: () => {
            navigation.goBack();
          },
        },
      ],
      { cancelable: false }
    );
    return true;
  };

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      handleBackPress
    );

    return () => backHandler.remove();
  }, []);

  useEffect(() => {
    const savedCpf = localStorage.getItem("cpf");
    if (savedCpf) {
      setValueCpf(savedCpf);
    }
  }, []);

  const MyButton = ({ onPress, title }) => (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <Text style={styles.buttonText}>{title}</Text>
    </TouchableOpacity>
  );

  const handleSaveCpf = () => {
    const cpf = valueCpfRef.current.value;
    if (cpf && cpf.trim() !== "") {
      setValueCpf(cpf);
      localStorage.setItem("cpf", cpf);
    }
  };

  useBackHandler(() => {
    Swal.fire({
      title: "Tem certeza que deseja sair?",
      text: "Ao sair você irá cancelar seu pedido.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, cancelar pedido!",
      cancelButtonText: "Não, continuar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
        clearBag();
        history.push(`/`);
      }
    });

    return true;
  });

  const handleCloseModal = () => {
    Swal.fire({
      title: "Tem certeza que deseja sair?",
      text: "Ao sair você irá cancelar seu pagamento.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Não, continuar",
      confirmButtonText: "Sim, cancelar pedido!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      }
    });
  };

  useEffect(() => {
    let intervalId;

    async function getUserTransactionId() {
      try {
        const fetchTransactionId = await fetch(
          `https://api.zappedis.com.br/${companyData.url}/order/validate/pix/${transactionId}`
        );

        if (!fetchTransactionId.ok) {
          throw new Error(
            `Erro ao buscar a transação: ${fetchTransactionId.status}`
          );
        }

        const response = await fetchTransactionId.json();
        const data = response.response;

        setTransactionIdPix(data);
        setRequestMade(true);

        if (fetchTransactionId.status === 400) {
          Swal.close();
          Swal.fire({
            html: `<h3>Erro ao processar o pagamento, tente novamente.</h3>`,
            icon: "error",
          });
          return;
        }

        if (data.status_payment.name === "Sucesso") {
          let statusPayment = document.querySelector("#msg-waiting-payment");
          let loaderClass = document.getElementsByClassName("loader")[0];
          statusPayment.innerHTML = "Pagamento realizado com sucesso!";
          loaderClass.classList.add("hidden");
          setIsPaymentSuccessful(true);
          if (honestMarket) {
            if (isNotExternal) {
              let timerInterval;
              let timeSecontActual = companyData.time_market_self;
              Swal.fire({
                html: `
                <h3>Para abrir a geladeira digite o código abaixo.</h3>
                <h1><bold>${newCodeMarketSelf.current}#</bold></h1>
                  <div class="wrap-circles">
                  <div class="circle per-0">
                    <div class="inner"><b>${timeSecontActual}</b></div>
                  </div>

                <div class="tutorial-market-self">Ao digitar o código numérico e # a geladeira acenderá a luz verde, PORTA ABERTA Iniciará um beep e você terá 15 segundos para abrir a porta, após isso ela travará novamente.</div>
                </div>
            `,
                allowOutsideClick: false,
                allowEscapeKey: false,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const b = Swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent =
                      timeSecontActual != 0
                        ? parseInt(
                            timeSecontActual != 0
                              ? (timeSecontActual = timeSecontActual - 1)
                              : 0
                          )
                        : "OK";
                    if (timeSecontActual == 0) {
                      Swal.hideLoading();
                    }
                    document.querySelector(
                      ".circle"
                    ).style.backgroundImage = `conic-gradient(rgb(255, 136, 0) ${
                      (timeSecontActual * 100) / companyData.time_market_self
                    }%, rgb(181, 179, 179)  0)`;
                  }, 1000);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.close();
                  clearBag();
                  history.push(`/`);
                }
              });
            } else if (isExternal) {
              Swal.fire({
                html: `<h3>Compra efetuada com sucesso!</h3>`,
                icon: "success",
                confirmButtonText: "Continuar",
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.close();
                  clearBag();
                  history.push(`/`);
                }
              });
            } else {
              Swal.close();
              clearBag();
              history.push(`/`);
            }
          } else {
            msgWhats({
              response,
              bag,
              companyData,
              voucherSelected,
              printTotalValue,
              printVoucherValue,
              calculateTotalItems,
              totalVoucher,
              paymentForm,
              user,
              changeMoney,
              neighborhood,
              orderMode,
              changeOrderMode,
              getOrderCode,
              isFreeShipping,
            });
          }
        }
      } catch (error) {
        console.error("Erro ao buscar a transação:", error);
      }
    }

    if (
      paymentForm !== null &&
      Helper.sanitizeString(paymentForm.description).includes("pix") &&
      paymentForm?.activate_acquirer
    ) {
      if (transactionId && !isPaymentSuccessful) {
        intervalId = setInterval(getUserTransactionId, 5000);
      }
    }

    return () => clearInterval(intervalId);
  }, [transactionId, isPaymentSuccessful]);

  function paymentCanceled() {
    Swal.close();
    Swal.fire({
      html: `<h3>Pagamento Cancelado</h3>`,
      icon: "error",
    });
    Swal.close();
    clearBag();
    history.push(`/`);
  }

  useEffect(() => {
    let timeoutId;

    if (requestMade && !isPaymentSuccessful) {
      timeoutId = setTimeout(() => {
        paymentCanceled();
      }, 600000);
    }

    return () => clearTimeout(timeoutId);
  }, [requestMade, isPaymentSuccessful]);

  useEffect(() => {
    if (bag.length === 0) {
      history.goBack();
    }

    function getUserData() {
      if (!cookies.user) {
        setVisibleFormSign(true);
      } else {
        setUser(cookies.user);
      }
    }

    getUserData();
  }, [cookies]);

  useEffect(() => {
    handleCardPayment();
  }, [paymentForm]);

  useEffect(() => {
    cards = JSON.parse(localStorage.getItem("history_card"));
    setCreditCard(cards);
    handleCardPayment();
    setSearchCard(false);
  }, [searchCard, isVisibleNewCard]);

  if (honestMarket) {
    changeOrderMode("0");
    SelectedChangeMoney(0);
  }

  const handleCardPayment = () => {
    setSearchCard(true);
    if (paymentForm != null) {
      if (
        Helper.sanitizeString(paymentForm.description).includes("credito") &&
        paymentForm?.activate_acquirer
      ) {
        setUsePix(false);
        if ((cards?.length !== 0) & (cards !== null)) {
          cards.filter((item) => {
            if (item.active) {
              if (paymentForm?.activate_acquirer) {
                setUseCard(true);
                return setCreditCard(item);
              }
            }
            setUseCard(true);
            return item.length !== 0 ? "" : setIsVisibleNewCards(true);
          });
          setUseCard(true);
          return !Boolean(creditCard) && setIsVisibleNewCards(true);
        }
        setUseCard(true);
        return setIsVisibleNewCards(true);
      } else if (
        Helper.sanitizeString(paymentForm.description).includes("pix") &&
        paymentForm?.activate_acquirer
      ) {
        setUsePix(true);
      } else if (
        Helper.sanitizeString(paymentForm.description).includes("dinheiro") &&
        paymentForm?.activate_acquirer
      ) {
        setUsePix(false);
      }
      setUseCard(false);
    }
  };

  useEffect(() => {
    if (cookies.user) {
      setPhone(cookies.user.phone);
    }
  }, [cookies.user]);

  useEffect(() => {
    getNeighborhoodActual();
  }, []);

  async function getNeighborhoodActual() {
    const {
      data: { response },
    } = await apiDelivery.get(`${company}/neighborhood`);

    const expires = new Date();
    expires.setDate(expires.getDate() + 365);

    response.find((item) => {
      if (cookies.user) {
        let neighborhoodUser = JSON.parse(cookies.user.neighborhood);
        if (item.neighborhood == neighborhoodUser.neighborhood) {
          return setCookies(
            "user",
            JSON.stringify({
              id: cookies.user.id,
              name: cookies.user.name,
              address: cookies.user.address,
              complement: cookies.user.complement,
              referencePoint: cookies.user.referencePoint,
              numberAddress: cookies.user.numberAddress,
              email: cookies.user.email,
              phone: cookies.user.phone,
              neighborhood: JSON.stringify(item),
            }),
            {
              expires,
              maxAge: 63072000,
            }
          );
        }
      }
    });
  }

  async function getVoucherData() {
    setTotalVoucher(0);
    setVoucherValue([]);

    const {
      data: { response },
    } = await apiDelivery.get(
      `${company}/customer/${Helper.onlyNumbers(phone)}/voucher`
    );

    response.map((product, index) => {
      let value = JSON.parse(product.available_value);
      voucherValue.push(value);
    });
    if (voucherValue !== null) {
      setTotalVoucher(
        voucherValue.reduce((total, currentElement) => {
          return total + currentElement;
        }, 0)
      );
    }
  }

  useEffect(() => {
    if (phone.length > 0) {
      getVoucherData();
    }
    if (orderMode === "0") {
      setPickupOnEstablishment(true);
    }
    if (orderMode === "1" && !isFreeShipping) {
      setPickupOnEstablishment(false);
    }
    if (orderMode === "1" && isFreeShipping) {
      setPickupOnEstablishment(true);
    }
  }, [phone, orderMode]);

  function move() {
    let elem = document.getElementById("greenBar");
    let stepValue = 0;
    let id = setInterval(frame, 500);

    function frame() {
      if (stepValue >= 100) {
        clearInterval(id);
      } else {
        elem.style.width = stepValue + 10 + "%";
        elem.innerHTML = stepValue + 10 + "%";
        stepValue = stepValue + 10;
      }
    }
  }

  async function sendOrderCode() {
    setConfirmation(false);
    setLoading(true);
    const list = bag.map(({ item, amount, complements }) => {
      return Helper.calculateValueItem(item, amount, complements);
    });
    let items = bag.map((item, index) => {
      const verifyIsRedo = () => {
        if (item.complements.length > 0) {
          if (item.complements[0].items) {
            return Helper.getComplementByQuantity(item.complements);
          }

          if (!item.complements[0].items) {
            return item.complements;
          }
        }
        return item.complements;
      };
      return {
        quantity: item.amount,
        product_id: item.item.id,
        price: isHigherValue == true ? list[index] : item.item.sale_price,
        obs: item.observation,
        complements:
          verifyIsRedo().length > 0
            ? verifyIsRedo().map((complement, index) => {
                const ComplementId = () => {
                  if (complement.product_complement) {
                    return complement.product_complement.id;
                  }

                  if (complement.deliveryComplementId) {
                    return complement.deliveryComplementId;
                  }
                };
                return {
                  quantity: complement.quantity,
                  product_complement_id: ComplementId(),
                  higherValue: complement.higherValue,
                };
              })
            : item.complements,
      };
    });
    const verifyPaymentForm = () => {
      return paymentForm && printTotalValue() > 0 ? paymentForm.id : null;
    };

    const dataOrder = {
      neighborhood_id: orderMode === "0" ? null : neighborhood.id,
      company_id: companyData.id,
      total_order: calculateTotalBag(),
      change: changeMoney,
      is_free_shipping: pickupOnEstablishment,
      is_voucher: voucherSelected,
      customer_id: user.id,
      form_of_payment_id: verifyPaymentForm(),
      latitude: latitude,
      longitude: longitude,
      accuracy: parseInt(accuracy),
      items: items,
      discount_coupon_id: null,
    };

    const savedCpf = localStorage.getItem("cpf");
    const dataOrderCard = {
      neighborhood_id: orderMode === "0" ? null : neighborhood.id,
      company_id: companyData.id,
      total_order: calculateTotalBag(),
      change: changeMoney,
      is_free_shipping: pickupOnEstablishment,
      is_voucher: voucherSelected,
      customer_id: user.id,
      form_of_payment_id: verifyPaymentForm(),
      latitude: latitude,
      longitude: longitude,
      accuracy: parseInt(accuracy),
      items: items,
      discount_coupon_id: null,
      document: useCard ? creditCard?.cpf : savedCpf || null,
      card_number: creditCard?.card_number,
      holder: creditCard?.holder,
      expiration_month: creditCard?.expiration_month,
      cvc: creditCard?.cvc,
      brand: creditCard?.brand,
    };

    if (creditCard?.expiration_year) {
      dataOrderCard.expiration_year = `20${creditCard.expiration_year}`;
    }

    return apiDelivery
      .post(
        `${company}/order`,
        (useCard || usePix) && paymentForm?.activate_acquirer
          ? dataOrderCard
          : dataOrder
      )
      .then(({ data: { response } }) => {
        setLoading(false);

        if (!response || response === null) {
          Swal.fire({
            html: `<h3>Erro 01: Erro ao processar o pagamento, tente usar outro método de pagamento.</h3>`,
            icon: "error",
          });
          return;
        }

        setTransactionId(response.credit_card_transaction_id);
        if (response.code_market_self) {
          newCodeMarketSelf.current = response.code_market_self;
          localStorage.setItem("code_market_self", response.code_market_self);
        }
        if (paymentForm) {
          localStorage.setItem("lastPaymentForm", JSON.stringify(paymentForm));
        }
        let timerInterval;
        let timeSecontActual = companyData.time_market_self;
        setLoading(false);

        if (honestMarket) {
          sendNotification(companyData.id);
          if (
            paymentForm !== null &&
            Helper.sanitizeString(paymentForm.description).includes("pix") &&
            paymentForm?.activate_acquirer
          ) {
            copy(response.pix_key);
            Swal.fire({
              html: `<h3>Seu pix foi gerado e copiado com sucesso! cole o link abaixo para app do seu banco!</h3>
                <div class="loading-payment">
                  <div class="loader"></div>
                  <h4 id="msg-waiting-payment">Aguardando pagamento</h4>
                  <button id="closeModalBtn" class="close-modal-btn">✕</button>
                </div>
                <div>
                <div class="input-group">
                  <input class="inputPix" value="${response.pix_key}" readonly/>
                  <button id="copyPixKey" onclick="copyPix()">Copiar</button>
                </div>
              `,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              didOpen: () => {
                let copyPixKey = document.querySelector("#copyPixKey");

                copyPixKey.addEventListener("click", () => {
                  copy(response.pix_key);
                  toast.dark("Pix copiado com sucesso!");
                });
                const closeModalBtn = document.getElementById("closeModalBtn");
                closeModalBtn.addEventListener("click", handleCloseModal);
              },
            });
          } else if (
            paymentForm !== null &&
            Helper.sanitizeString(paymentForm?.description).includes("pix")
          ) {
            copy(response.pix_key);
            Swal.fire({
              html: `<h3>Seu pix foi gerado e copiado com sucesso! cole o link abaixo para app do seu banco!</h3>
                  <div class="input-group">
                    <input class="inputPix" value="${response.pix_key}" readonly/>
                    <button id="copyPixKey" onclick="copyPix()">Copiar</button>
                  </div>
          `,
              icon: "success",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
              allowEscapeKey: false,
              didOpen: () => {
                let copyPixKey = document.querySelector("#copyPixKey");

                copyPixKey.addEventListener("click", () => {
                  copy(response.pix_key);
                  toast.dark("Pix copiado com sucesso!");
                });
              },
            }).then((result) => {
              if (result.isConfirmed && isNotExternal) {
                copy(response.pix_key);
                Swal.fire({
                  html: `
                  <h3>Para abrir a geladeira digite o código abaixo.</h3>
                  <h1><bold>${response.code_market_self}#</bold></h1>
                    <div class="wrap-circles">
                    <div class="circle per-0">
                      <div class="inner"><b>${timeSecontActual}</b></div>
                    </div>
      
                  <div class="tutorial-market-self">Ao digitar o código numérico e # a geladeira acenderá a luz verde, PORTA ABERTA Iniciará um beep e você terá 15 segundos para abrir a porta, após isso ela travará novamente.</div>
                  </div>
              `,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                    const b = Swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent =
                        timeSecontActual != 0
                          ? parseInt(
                              timeSecontActual != 0
                                ? (timeSecontActual = timeSecontActual - 1)
                                : 0
                            )
                          : "OK";
                      if (timeSecontActual == 0) {
                        Swal.hideLoading();
                      }
                      document.querySelector(
                        ".circle"
                      ).style.backgroundImage = `conic-gradient(rgb(255, 136, 0) ${
                        (timeSecontActual * 100) / companyData.time_market_self
                      }%, rgb(181, 179, 179)  0)`;
                    }, 1000);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  if (result.dismiss === Swal.DismissReason.timer) {
                    console.log("I was closed by the timer");
                  }
                });
              } else if (result.isConfirmed && isExternal) {
                setLoading(false);
                Swal.fire({
                  html: `<h3>Compra efetuada com sucesso!</h3>`,
                  icon: "success",
                  confirmButtonText: "Continuar",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.close();
                    clearBag();
                    history.push(`/`);
                  }
                });
              }
            });
          } else {
            setLoading(false);
            Swal.fire({
              html: `<h3>Compra efetuada com sucesso!</h3>`,
              icon: "success",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed && isNotExternal) {
                Swal.fire({
                  html: `
                  <h3>Para abrir a geladeira digite o código abaixo.</h3>
                  <h1><bold>${response.code_market_self}#</bold></h1>
                    <div class="wrap-circles">
                    <div class="circle per-0">
                      <div class="inner"><b>${timeSecontActual}</b></div>
                    </div>
      
                  <div class="tutorial-market-self">Ao digitar o código numérico e # a geladeira acenderá a luz verde, PORTA ABERTA Iniciará um beep e você terá 15 segundos para abrir a porta, após isso ela travará novamente.</div>
                  </div>
              `,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                    const b = Swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent =
                        timeSecontActual != 0
                          ? parseInt(
                              timeSecontActual != 0
                                ? (timeSecontActual = timeSecontActual - 1)
                                : 0
                            )
                          : "OK";
                      if (timeSecontActual == 0) {
                        Swal.hideLoading();
                      }
                      document.querySelector(
                        ".circle"
                      ).style.backgroundImage = `conic-gradient(rgb(255, 136, 0) ${
                        (timeSecontActual * 100) / companyData.time_market_self
                      }%, rgb(181, 179, 179)  0)`;
                    }, 1000);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  Swal.close();
                  clearBag();
                  history.push(`/`);
                });
              } else if (result.isConfirmed && isExternal) {
                Swal.close();
                clearBag();
                history.push(`/`);
              }
            });
          }
        } else {
          setLoading(false);
          sendNotification(companyData.id);
          if (
            paymentForm !== null &&
            Helper.sanitizeString(paymentForm.description).includes("pix") &&
            paymentForm?.activate_acquirer
          ) {
            copy(response.pix_key);
            Swal.fire({
              html: `<h3>Seu pix foi gerado e copiado com sucesso! cole o link abaixo para app do seu banco!</h3>
                <div class="loading-payment">
                  <div class="loader"></div>
                  <h4 id="msg-waiting-payment">Aguardando pagamento</h4>
                  <button id="closeModalBtn" class="close-modal-btn">✕</button>
                </div>
                <div>
                <div class="input-group">
                  <input class="inputPix" value="${response.pix_key}" readonly/>
                  <button id="copyPixKey" onclick="copyPix()">Copiar</button>
                </div>
              `,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              didOpen: () => {
                let copyPixKey = document.querySelector("#copyPixKey");

                copyPixKey.addEventListener("click", () => {
                  copy(response.pix_key);
                  toast.dark("Pix copiado com sucesso!");
                });
                const closeModalBtn = document.getElementById("closeModalBtn");
                closeModalBtn.addEventListener("click", handleCloseModal);
              },
            }).then((result) => {
              if (result.isConfirmed) {
                copy(response.pix_key);
                msgWhats({
                  response,
                  bag,
                  companyData,
                  voucherSelected,
                  printTotalValue,
                  printVoucherValue,
                  calculateTotalItems,
                  totalVoucher,
                  paymentForm,
                  user,
                  changeMoney,
                  neighborhood,
                  orderMode,
                  changeOrderMode,
                  getOrderCode,
                  isFreeShipping,
                });
              }
            });
          } else if (
            paymentForm !== null &&
            Helper.sanitizeString(paymentForm.description).includes("pix")
          ) {
            copy(response.pix_key);
            Swal.fire({
              html: `<h3>Seu pix foi gerado e copiado com sucesso! cole o link abaixo para app do seu banco!</h3>
              <div class="input-group">
                <input class="inputPix" value="${response.pix_key}" readonly/>
                <button id="copyPixKeyThree" onclick="copyPixThree()">Copiar</button>
              </div>
        `,
              icon: "success",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
              allowEscapeKey: false,
              didOpen: () => {
                let copyPixKeyThree =
                  document.querySelector("#copyPixKeyThree");

                copyPixKeyThree.addEventListener("click", () => {
                  copy(response.pix_key);
                  toast.dark("Pix copiado com sucesso!");
                });
              },
            }).then((result) => {
              if (result.isConfirmed) {
                copy(response.pix_key);
                msgWhats({
                  response,
                  bag,
                  companyData,
                  voucherSelected,
                  printTotalValue,
                  printVoucherValue,
                  calculateTotalItems,
                  totalVoucher,
                  paymentForm,
                  user,
                  changeMoney,
                  neighborhood,
                  orderMode,
                  changeOrderMode,
                  getOrderCode,
                  isFreeShipping,
                });
              }
            });
          } else {
            setLoading(false);
            msgWhats({
              response,
              bag,
              companyData,
              voucherSelected,
              printTotalValue,
              printVoucherValue,
              calculateTotalItems,
              totalVoucher,
              paymentForm,
              user,
              changeMoney,
              neighborhood,
              orderMode,
              changeOrderMode,
              getOrderCode,
              isFreeShipping,
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.response[0] == "duplicate order") {
          return Swal.fire({
            html: `
              <h5>Aguarde um momento para comprar novamente o mesmo produto!</h5>
          `,
            icon: "info",
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
        if (
          error.response.data.response[0] ==
          "Erro ao processar pagamento, tente novamente"
        )
          toast.dark("Erro ao processar pagamento, tente novamente");
        setTimeout(() => {
          history.push(`/`);
        }, 3000);
      });
  }

  function calculateTotalBag() {
    const priceItems = calculateTotalItems();

    if (
      neighborhood != null &&
      orderMode === "1" &&
      !isFreeShipping &&
      voucherSelected === false
    )
      return priceItems;

    return priceItems;
  }

  function calculateTotalItems() {
    const list = bag.map(({ item, amount, complements }) => {
      return Helper.calculateValueItem(item, amount, complements);
    });

    if (list.length > 0) {
      const value = list.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      });
      return value;
    }
    return 0;
  }

  function printTotalValue() {
    if (
      voucherSelected === true &&
      isFreeShipping &&
      totalVoucher > calculateTotalBag()
    ) {
      return 0;
    }

    if (
      voucherSelected === true &&
      isFreeShipping &&
      totalVoucher <= calculateTotalBag()
    ) {
      return calculateTotalBag() - totalVoucher;
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode !== "1" &&
      totalVoucher > calculateTotalBag()
    ) {
      return 0;
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode !== "1" &&
      totalVoucher <= calculateTotalBag()
    ) {
      return calculateTotalBag() - totalVoucher;
    }
    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode === "1" &&
      totalVoucher >= calculateTotalBag() + parseFloat(neighborhood.price)
    ) {
      return 0;
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode === "1" &&
      totalVoucher < calculateTotalBag() + parseFloat(neighborhood.price)
    ) {
      return (
        calculateTotalBag() - totalVoucher + parseFloat(neighborhood.price)
      );
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode === "1" &&
      totalVoucher < calculateTotalBag() + parseFloat(neighborhood.price)
    ) {
      return (
        calculateTotalBag() - totalVoucher + parseFloat(neighborhood.price)
      );
    }

    if (voucherSelected === false && !isFreeShipping && orderMode === "1") {
      return calculateTotalBag() + parseFloat(neighborhood.price);
    }

    if (voucherSelected === false) {
      return calculateTotalBag();
    }
  }

  function printVoucherValue() {
    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode === "1" &&
      totalVoucher > calculateTotalItems() + parseFloat(neighborhood.price)
    ) {
      return calculateTotalItems() + parseFloat(neighborhood.price);
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode === "1" &&
      totalVoucher < calculateTotalItems() + parseFloat(neighborhood.price)
    ) {
      return totalVoucher;
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode !== "1" &&
      totalVoucher > calculateTotalItems()
    ) {
      return calculateTotalItems();
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode !== "1" &&
      calculateTotalItems() >= totalVoucher
    ) {
      return totalVoucher;
    }

    if (
      voucherSelected === true &&
      isFreeShipping &&
      calculateTotalItems() >= totalVoucher
    ) {
      return totalVoucher;
    }

    if (
      voucherSelected === true &&
      isFreeShipping &&
      calculateTotalItems() < totalVoucher
    ) {
      return calculateTotalItems();
    }

    return calculateTotalItems();
  }

  async function sendOrder() {
    sendOrderCode().finally(() => {
      clearVoucher();
      setUseCard(false);
      setCreditCard([]);
      // if (isEnvProduction()) {
      //   history.push(`/`);
      // } else {
      //   history.push(`/`);
      // }
    });
  }

  function handleOrder() {
    toast.dismiss();

    let totalValue = printTotalValue();
    if (totalValue <= 0) {
      toast.dark("Não é possível fazer um pedido de 0 reais");
      return;
    }
    const savedCpf = localStorage.getItem("cpf");
    let currentCpf = valueCpfRef.current ? valueCpfRef.current.value : "";

    if (paymentForm?.activate_acquirer && usePix) {
      if (validarCPF(currentCpf || valueCpf)) {
      } else {
        toast.dark("Digite um CPF válido");
        return;
      }
    }

    setValueCpf(savedCpf);

    if (paymentForm?.activate_acquirer && usePix) {
      if (!user.email) {
        toast.dark("Email do usuário obrigatório");
        return;
      }
    }

    if (useCard && !creditCard) {
      toast.dark("Adicione um cartão");
      return;
    }

    if (user == null) {
      toast.dark("Informe os seus dados");
      return;
    }

    if (orderMode == null || orderMode === 0 || orderMode === 1) {
      toast.dark("Informe o método de entrega");
      return;
    }

    if (
      (paymentForm == null && voucherSelected === false) ||
      (paymentForm === null && voucherSelected === true && totalValue > 0)
    ) {
      toast.dark("Informe a forma de pagamento");
      return;
    }

    setConfirmation(true);
  }

  const acquirer_pix = companyData?.acquirer;
  const credit_card = companyData?.acquirer;

  return (
    <KeyboardAvoidingView behavior="padding" style={styles.container}>
      <Preloader status={loading} />
      <View style={styles.container}>
        <NewCard
          toggleVisible={() => setIsVisibleNewCards(!isVisibleNewCard)}
          visible={isVisibleNewCard}
          setSearchCard={setSearchCard}
        />
        <FormSign
          company={companyData}
          visible={visibleFormSign}
          onClose={() => {
            setVisibleFormSign(false);
          }}
          history={history}
        />
        <ModalChange printVoucherValue={printVoucherValue()} />
        <OptionsPayment
          visible={visibleModalPayment}
          onClose={() => setVisibleModalPayment(false)}
        />
        <ModalConfirmationOrder
          visible={confirmation}
          onClose={() => setConfirmation(false)}
          order={() => sendOrder()}
        />
        <OptionItemModal
          product={product}
          navigation={navigation}
          onEditItem={() => {
            if (isEnvProduction()) {
              history.push(`/product/`, {
                id: product.id,
                amountSelected: product.amount,
                item: product.item,
                selectedComplements: product.complements,
                observationItem: product.observation,
              });
            } else {
              history.push(`/${company}/product`, {
                id: product.id,
                amountSelected: product.amount,
                item: product.item,
                selectedComplements: product.complements,
                observationItem: product.observation,
              });
            }
          }}
          onRemove={() => {
            removeItem(product.id);
            if (isEnvProduction()) {
              history.push(`/`, {
                id: product.id,
                amountSelected: product.amount,
              });
            } else {
              history.push(`/`, {
                id: product.id,
                amountSelected: product.amount,
              });
            }
          }}
          onClose={() => {
            setProduct(null);
          }}
        />
        {!loading ? <NavBar history={history} /> : ""}
        <ScrollView style={styles.content}>
          <TouchableOpacity onPress={() => setVisibleFormSign(true)}>
            <View style={styles.header}>
              <Text style={{ fontSize: 16, fontWeight: "600" }}>
                Perfil
                <AiFillEdit
                  style={{ float: "right", color: buttonColor, fontSize: 25 }}
                />
              </Text>
              <Text style={styles.addressInput}>
                Endereço: {user != null ? formatAddress(user.address) : ""}
              </Text>
              <Text style={styles.textInput}>
                Nome: {user != null ? user.name : ""}
              </Text>
              <Text style={styles.textInput}>
                Telefone: {user != null ? user.phone : ""}
              </Text>
              <Text style={styles.textInput}>
                E-mail: {user != null ? user.email : ""}
              </Text>
            </View>
          </TouchableOpacity>
          {!honestMarket ? (
            <View style={styles.header}>
              <TouchableOpacity onPress={() => setVisibleFormSign(true)}>
                <Text
                  style={{ fontSize: 16, fontWeight: "600", marginBottom: 20 }}
                >
                  Entrega
                  <AiFillEdit
                    style={{ float: "right", color: buttonColor, fontSize: 25 }}
                  />
                </Text>
              </TouchableOpacity>
              <SelectDeliveryMethod onChange={() => changeOrderMode()} />
            </View>
          ) : (
            ""
          )}
          <View style={styles.containerPaymentForm}>
            <TouchableOpacity
              onPress={() => {
                setVisibleModalPayment(true);
              }}
            >
              <View style={styles.btnSelectPaymentForm}>
                <Text style={styles.titlePaymentFormText}>
                  Formas de pagamento
                </Text>
                <Text
                  style={[
                    styles.titleSwitchPaymentForm,
                    { color: buttonColor },
                  ]}
                  numberOfLines={1}
                >
                  {paymentForm != null ? paymentForm.description : "Escolher"}
                </Text>
              </View>
            </TouchableOpacity>
            {useCard && (
              <View style={styles.containerPaymentFormCard}>
                <CardPayment creditCard={creditCard} />
              </View>
            )}
            {usePix && (
              <SafeAreaView style={styles.containerPaymentFormPix}>
                {!valueCpf || localStorage.getItem("cpf") === null ? (
                  <>
                    <Text style={{ marginBottom: 10, fontWeight: "bold" }}>
                      CPF:
                    </Text>
                    <View style={styles.containerCpf}>
                      <TextInput
                        placeholder="Digite seu CPF"
                        style={styles.inputCpf}
                        ref={valueCpfRef}
                        onChange={(e) => {
                          valueCpfRef.current.value = e.nativeEvent.text;
                        }}
                      />
                      <MyButton title="Salvar CPF" onPress={handleSaveCpf} />
                    </View>
                  </>
                ) : (
                  <View style={styles.containerPaymentFormPix}>
                    <Text style={{ marginBottom: 10, fontWeight: "bold" }}>
                      CPF:
                    </Text>
                    <View style={styles.containerCpf}>
                      <TextInput
                        placeholder="Digite seu CPF"
                        style={styles.inputCpf}
                        value={valueCpf}
                        editable={false}
                      />
                      <MyButton title="Excluir CPF" onPress={clearCpf} />
                    </View>
                  </View>
                )}
              </SafeAreaView>
            )}
          </View>
          <View style={styles.containerEstablishment}>
            <Text style={{ fontSize: 15, fontWeight: "600" }}>Pedido</Text>
          </View>
          <View style={styles.listItens}>
            {bag.map(
              (
                { item, amount, complements, id, observation, higherValue },
                index
              ) => {
                const listComplements = [];
                complements.map((group) => {
                  if (group.product_complement) {
                    let objectSelected = listComplements.find((object) => {
                      return (
                        object.complement.id === group.product_complement.id
                      );
                    });

                    if (objectSelected != null) {
                      objectSelected.amount++;
                    } else {
                      listComplements.push({
                        complement: group.product_complement,
                        amount: 1,
                      });
                    }
                  }
                  group.items &&
                    group.items.map((item) => {
                      let objectSelected = listComplements.find((object) => {
                        return object.complement.id === item.id;
                      });

                      if (objectSelected != null) {
                        objectSelected.amount++;
                      } else {
                        listComplements.push({ complement: item, amount: 1 });
                      }
                    });
                });

                return (
                  <View key={index} style={styles.containerItem}>
                    <View style={styles.contentTitleItem}>
                      <View style={styles.titleItem}>
                        <Text style={styles.titleAmountItem}>{amount}x </Text>
                        <Text style={styles.titleItemText}>{item.name}</Text>
                        <Text style={styles.titleAmountItem}>
                          R${" "}
                          {Helper.maskMoney(
                            Helper.calculateValueItem(item, amount, complements)
                          )}
                        </Text>
                      </View>
                      {listComplements.map(({ complement, amount }) => {
                        return (
                          <View style={styles.titleItem} key={complement.id}>
                            <Text style={styles.textComplementItem}>
                              - {amount}x {complement.name}
                            </Text>
                          </View>
                        );
                      })}
                      {observation.length > 0 ? (
                        <View style={styles.titleItem}>
                          <Text style={styles.textComplementItem}>
                            Observação: {observation}
                          </Text>
                        </View>
                      ) : null}
                    </View>
                    <TouchableOpacity
                      style={styles.btnOptionItem}
                      onPress={() => {
                        setProduct({
                          id,
                          item,
                          amount,
                          complements,
                          observation,
                        });
                      }}
                    >
                      <FaEllipsisV />
                    </TouchableOpacity>
                  </View>
                );
              }
            )}
          </View>
          <View style={styles.containerTotals}>
            <View style={styles.contentItemTotals}>
              <Text style={styles.subtotalsText}>Subtotal</Text>
              <Text style={styles.subtotalsText}>
                R$ {Helper.maskMoney(calculateTotalItems())}
              </Text>
            </View>
            {neighborhood != null && orderMode === "1" && !isFreeShipping && (
              <View style={styles.contentItemTotals}>
                <Text style={styles.subtotalsText}>Entrega padrão</Text>
                <Text style={styles.subtotalsText}>
                  {" "}
                  R$ {Helper.maskMoney(parseFloat(neighborhood.price))}
                </Text>
              </View>
            )}

            {voucherSelected === true && (
              <View style={styles.contentItemTotals}>
                {totalVoucher > 0 && voucherSelected === true && (
                  <Text style={styles.subtotalsText}>Voucher</Text>
                )}

                <Text style={styles.subtotalsText}>
                  {"- R$ " + Helper.maskMoney(printVoucherValue())}
                </Text>
              </View>
            )}
            <View style={styles.contentItemTotals}>
              <Text style={styles.totalsText}>Total</Text>
              <Text style={styles.totalsText}>
                {"R$ " + Helper.maskMoney(printTotalValue())}
              </Text>
            </View>
          </View>
          <Change />
          <View style={styles.containerVouecher}>
            <View style={styles.btnSelectPaymentForm}>
              <Text
                style={{ fontSize: 16, fontWeight: "600", marginBottom: 10 }}
              >
                Voucher
              </Text>
              <Voucher
                visible={visibleModalVoucher}
                onClose={() => setVisibleModalVoucher(false)}
              />
              <TouchableOpacity
                onPress={() => {
                  setVisibleModalVoucher(true);
                }}
              >
                <Text
                  style={[
                    styles.titleSwitchPaymentForm,
                    { color: buttonColor },
                  ]}
                  numberOfLines={1}
                >
                  {voucherSelected
                    ? "Voucher Selecionado!"
                    : "Selecione um voucher"}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
        <View
          style={[
            styles.footer,
            loading ? { display: "none" } : { display: "block" },
          ]}
        >
          <TouchableOpacity
            onPress={() => {
              handleOrder();
            }}
          >
            <View
              style={[
                styles.btnFinish,
                { backgroundColor: buttonColor, color: buttonTextColor },
              ]}
            >
              <Text style={[styles.btnFinishText]}>Continuar</Text>
            </View>
          </TouchableOpacity>
        </View>
        <ToastContainer hideProgressBar={true} pauseOnHover />
      </View>
    </KeyboardAvoidingView>
  );
}
