import { StyleSheet } from "react-native";
import Colors from "../Colors";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflowY: 'auto',
  },
  overlayModal: {
    flex: 1,
    justifyContent: "space-around",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    backgroundColor: "#fff",
    padding: 10,
  },
  input: {
    padding: 10,
    marginBottom: 10,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
  },
  containerInput: {
    padding: 10,
  },
  labelInput: {
    marginBottom: 10,
    fontSize: 14,
  },
  confirmationButton: {
    borderRadius: 5,
    marginTop: 20,
    backgroundColor: Colors.PRIMARY,
    justifyContent: "center",
    alignItems: "center",
    height: 30,
  },
});
