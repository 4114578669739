import React, { useState, useEffect } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  SafeAreaView,
  Text,
} from "react-native";
import { InputCountItem } from "../../components/form/InputCountItem";
import { useOrderService } from "../../contexts/orderService";
import Helper from "../../utils/Helper";
import GroupComplementMenu from "../../components/GroupComplementMenu";

import ItemObservation from "../../components/ItemObservation";
import Colors from "../../components/Colors";
import NavBar from "../../components/NavBar";
import apiDelivery from "../../Api/apiDelivery";
import notPicture from "../../assets/images/notPicture.jpg";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { useParams } from "react-router-dom";

export default function ItemDetail({  location, history }) {
  const [loading, setLoading] = useState(false);
  const [data,setData] = useState(null);
  const [isDirectionAccess,setIsDirectionAccess] = useState(false);
  
  const {productId} = useParams();

  const company = getSubDomain();

  useEffect(()=>{
    async function getDataProduct(){
      if(location.state){
          setData({...location.state});
      }else{
        const {data: {response}} = await apiDelivery.get(`${company}/product/${productId}`);

        setData({
          item:response,
          amountSelected:null,
          selectedComplements:null,
          id:null,
          observationItem:null,
          restaurant:null,
        });

        setIsDirectionAccess(true);
      }

      setLoading(true)
    }

    getDataProduct();
  },[]);


  return loading ?  <ItemScreen history={history} isDirectionAccess={isDirectionAccess} {...data}/> : null;
}

function ItemScreen({item,amountSelected,selectedComplements,id, observationItem, restaurant, history,isDirectionAccess}){

  const {
    addItem,
    addEstablishment,
    editItem,
  } = useOrderService();

  const company = getSubDomain();

  const [amount, setAmount] = useState(1);
  const [complements, setComplements] = useState([]);
  const [groupComplement, setGroupComplement] = useState([]);
  const [observation, setObservation] = useState(observationItem || "");
  const [loading, setLoading] = useState(false);
  let isDisabled = checkItemsRequired();

  useEffect(() => {
    async function getDetailProduct() {
      const {
        data: { response },
      } = await apiDelivery.get(`/${company}/product/group_complements/${item.id}`);

      setGroupComplement(response);
      if (amountSelected != null) setAmount(amountSelected);
      if (selectedComplements != null) setComplements(selectedComplements);
    }

    getDetailProduct();
  }, []);

  function checkItemsRequired() {
    return (
      groupComplement
        .filter((group) => {
          return group.required == true;
        })
        .filter((group) => {
          const groupComplementSelected = complements.find((complement) => {
            return complement.groupId === group.id;
          });

          if (!groupComplementSelected) return true;

          return groupComplementSelected.items.length < group.minimum_quantity;
        }).length != 0
    );
  }

  function onChangeItems(items, id, name) {
    const groupComplementSelected = complements.find((complement) => {
      return complement.groupId == id;
    });

    if (!groupComplementSelected) {
      setComplements([...complements, { groupId: id, items, name }]);
    } else {
      setComplements(
        complements.map((item) => {
          if (item.groupId === id) item.items = items;
          return item;
        })
      );
    }
  }

  function handleBack(){
    if(isDirectionAccess){
      if(isEnvProduction()){
        history.push(`/`);
      }else{
        history.push(`/${company}`);
      }
    }else{
      history.goBack();
    }
    
  }

  function addNewItem() {
    addEstablishment(restaurant);
    addItem(item, amount, complements, observation);
    handleBack();
  }

  function editItemSelected() {
    editItem(id, item, amount, complements, observation);
    handleBack();
  }

  return (
    <View>
      <SafeAreaView style={styles.container}></SafeAreaView>
      <View style={{ width: "100%" }}>
        <NavBar
          history={history}
          restaurant={restaurant}
        />
      </View>
      <ScrollView
        style={styles.containerScroll}
        keyboardShouldPersistTaps="handled"
      >
        <View>
          <Image
            resizeMode="contain"
            style={styles.logo}
            source={{ uri: !item.picture ? notPicture : item.picture }}
          />
        </View>
        <View style={styles.content}>
          <Text h4 style={{marginHorizontal: 8}}>{item.name}</Text>
          <Text style={styles.descriptionItem}>{item.description}</Text>
          <Text style={styles.priceItem}>
            {item.sale_price <=0 ? '' : `A partir de R$ ${Helper.maskMoney(parseFloat(item.sale_price))}` }
            
          </Text>
        </View>
        <View style={{ marginVertical: 10 }}>
          {groupComplement.map((group, index) => {
            return (
              <GroupComplementMenu
                key={index}
                group={group}
                onChangeItems={onChangeItems}
                selectedComplements={selectedComplements}
              />
            );
          })}
        </View>
        
      </ScrollView>
      {loading ? (
        <></>
      ) :  null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerScroll: {
    flex: 1,
    backgroundColor: "#fff",
  },
  logo: {
    height: 200,
    width: "100%",
    marginVertical: 5,
    borderRadius: 5,
  },
  content: {
    marginHorizontal: 15,
  },
  containerAddItem: {
    marginTop: 20,
  },
  descriptionItem: {
    marginVertical: 11,
    color: "#666",
    marginHorizontal: 8
  },
  priceItem: {
    fontSize: 13,
    marginBottom: 20,
    marginHorizontal: 8
  },
  footer: {
    borderTopColor: "#f5f5f5",
    borderTopWidth: 1,
    backgroundColor: "#fff",
    bottom: 0,
    padding: 10,
    display: "flex",
    flexDirection: "row",
  },
  btnContainerAddItem: {
    flex: 1,
  },
  btnAddItem: {
    flex: 1,
    borderRadius: 5,
    marginLeft: 9,
    backgroundColor: Colors.PRIMARY,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 14,
  },
  btnTextItemAdd: {
    flex: 1,
    color: "#fff",
    fontSize: 15,
    alignContent: "center",
    width: "auto",
  },
});
