import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { styles } from "./styles.js";
import { GrRedo } from "react-icons/gr";
import NavBar from "../../components/NavBar";
import apiDelivery from "../../Api/apiDelivery.js";
import { getSubDomain, isEnvProduction } from "../../utils/url.js";
import { useHistory, useParams } from "react-router";
import Helper from "../../utils/Helper.js";
import { transformMonth, verifyStatus } from "../../utils/orders.js";
import { TouchableOpacity } from "react-native";
import { useOrderService } from "../../contexts/orderService.js";
import { useColor } from "../../contexts/colors.js";
import { sendOrderCode } from "../../actions/orderActions.js";

export default function MoreDetails() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dontRedo, setDontRedo] = useState(false);
  const { buttonColor, buttonTextColor } = useColor();
  const [codeMarketSelf, setCodeMarketSelf] = useState(null);

  const history = useHistory();
  const company = getSubDomain();

  const { addItems } = useOrderService();

  let companyData = localStorage.getItem(`company_${company}`);
  companyData = JSON.parse(companyData);

  useEffect(() => {
    getOrders();
    getCodeMarketSelf();
  }, []);

  const { orderId } = useParams();

  const getOrders = () => {
    if (loading) return;
    setLoading(true);
    apiDelivery.get(`${company}/order/${orderId}`).then((response) => {
      setLoading(false);
      setData(response.data.response[0]);
    });
  };

  const redirectRoute = (route) => {
    if (isEnvProduction()) {
      return history.push(`${route}`);
    } else {
      return history.push(`/${company}${route}`);
    }
  };

  const getCodeMarketSelf = () => {
    setLoading(true);
    apiDelivery.get(`${company}/order/${orderId}`).then((response) => {
      setLoading(false);
      setCodeMarketSelf(response.data.response.map(item => item.code_market_self));
    });
  }

  const redoOrder = () => {
    const id = Math.floor(new Date().getTime() + Math.random() * 1000);
    const orders = data.ordem_itens.map((attribute, index) => {
      return {
        id: id,
        item: attribute.product,
        amount: attribute.quantity,
        complements: attribute.ordem_complements,
        observation: attribute.obs,
      };
    });
    addItems(orders);
    redirectRoute("/");
  };

  const redoOrderAndSend = () => {
    const id = Math.floor(new Date().getTime() + Math.random() * 1000);
    const orders = data.ordem_itens.map((attribute, index) => {
      return {
        id: id,
        item: attribute.product,
        amount: attribute.quantity,
        complements: attribute.ordem_complements,
        observation: attribute.obs,
      };
    });
    addItems(orders);
    redirectRoute("/detailOrder");
  };

  function handleBack() {
    if (isEnvProduction()) {
      history.push(`/myOrders`);
    } else {
      history.push(`/${company}/myOrders`);
    }
  }

  const Card = ({ item }) => {
    if (!item) return null;

    const dateTime = item.date_order.split(" ")[1].split(":");

    const day = item.date_order.split("/")[0];
    const month = item.date_order.split("/")[1];
    let voucherValue = 0;

    if (item.vouchers.length > 0) {
      voucherValue = item.vouchers[0].value;
    }

    const status = verifyStatus(item.status);
    return (
      <View style={styles.container}>
        <View style={styles.containerCards}>
          <View style={styles.cards}>
            <View style={styles.header}>
              <Text style={styles.title}>{item.order_code}</Text>
              <Text style={{ color: status.color }}>{status.title}</Text>
            </View>

            <View style={styles.containerListProducts}>
              {codeMarketSelf && <Text style={styles.labelListProducts}>Código da Geladeira: #{codeMarketSelf}</Text>}
            </View>

            <View style={styles.lineBottom} />

            <View style={styles.containerListProducts}>
              <Text style={styles.labelListProducts}>Produtos</Text>
              {item.ordem_itens.map((data, index) => {
                data.product.deleted_at != null ||
                  (data.product.category.is_higher_value === true &&
                    setDontRedo(true));
                return (
                  <>
                    <View style={styles.contentCard}>
                      <Text style={styles.listProductsDescription}>
                        {data.product.name}
                      </Text>
                      <Text style={styles.listProductsQuantity}>
                        {data.quantity}x
                      </Text>
                      <Text style={styles.listProductsPrice}>
                        R$ {Helper.maskMoney(JSON.parse(data.price))}
                      </Text>
                    </View>

                    {data.ordem_complements.length > 0 &&
                      data.ordem_complements.map((data, index) => {
                        return (
                          <View style={styles.contentCard}>
                            <Text style={styles.listComplementsDescription}>
                              {data.product_complement.name}
                            </Text>
                            <Text style={styles.listComplementsQuantity}>
                              {data.quantity}x
                            </Text>
                            <Text style={styles.listComplementsPrice}>
                              R${" "}
                              {Helper.maskMoney(
                                JSON.parse(data.product_complement.price)
                              )}
                            </Text>
                          </View>
                        );
                      })}
                  </>
                );
              })}
            </View>
            <View style={styles.containerListProducts}>
              <Text style={styles.labelListProducts}>Entrega</Text>
              <View style={styles.contentCard}>
                <Text style={styles.listProductsDescription}>
                  {item.is_free_shipping === true &&
                    "Retirada no Estabelecimento"}
                  {item.is_free_shipping === false &&
                    data.neighborhood &&
                    data.customer.address +
                      " - " +
                      data.neighborhood.neighborhood}
                </Text>
              </View>
            </View>

            <View style={styles.lineBottom} />

            <View style={styles.contentCard}>
              <Text>Data: </Text>
              <Text>
                {day} de {transformMonth(month)}
              </Text>
            </View>

            <View style={styles.contentCard}>
              <Text>Horário: </Text>
              <Text>
                {dateTime[0]}h{dateTime[1]}
              </Text>
            </View>

            <View style={styles.contentCard}>
              <Text>Pagamento: </Text>
              <Text>
                {item.form_of_payment !== null
                  ? item.form_of_payment.description
                  : "Voucher"}
              </Text>
            </View>

            {item.is_free_shipping !== true && item.neighborhood && (
              <View style={styles.contentCard}>
                <Text>Valor Entrega:</Text>
                <Text>
                  R$ {Helper.maskMoney(JSON.parse(item.neighborhood.price))}
                </Text>
              </View>
            )}

            {item.vouchers.length > 0 && (
              <View style={styles.contentCard}>
                <Text>Voucher: </Text>
                <Text>
                  - R$ {Helper.maskMoney(JSON.parse(item.vouchers[0].value))}
                </Text>
              </View>
            )}

            {item.change > 0 && (
              <View style={styles.contentCard}>
                <Text>Troco:</Text>
                <Text>R$ {Helper.maskMoney(JSON.parse(item.change))}</Text>
              </View>
            )}

            <View style={styles.contentCard}>
              <Text>Total:</Text>
              {item.is_free_shipping !== true && item.neighborhood ? (
                <Text>
                  R${" "}
                  {Helper.maskMoney(
                    JSON.parse(item.total_order) - voucherValue
                  )}
                </Text>
              ) : (
                <Text>R$ {Helper.maskMoney(JSON.parse(item.total_order))}</Text>
              )}
            </View>
            {!dontRedo && (
              <View style={{ width: "100%", alignItems: "center", display: "flex", flexDirection: "row", gap: 5, justifyContent: "center" }}>
                <TouchableOpacity
                  style={[styles.redoOrder, { backgroundColor: buttonColor }]}
                  onPress={() => redoOrder()}
                >
                  <Text style={{ color: buttonTextColor }}>Adicionar a sacola</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.redoOrder, { backgroundColor: buttonColor }]}
                  onPress={() => redoOrderAndSend()}
                >
                  <Text style={{ color: buttonTextColor }}>Refazer Pedido</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  };

  return (
    <>
      <NavBar onBackPage={handleBack} />
      <View style={{ flex: 1, flexDirection: "row" }}>
        <Card item={data} />
      </View>
    </>
  );
}
